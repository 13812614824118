<script setup lang="ts">
// composables
const { locale: currentLocale, locales, setLocale } = useI18n();

const locale = computed({
  get: () => currentLocale.value,
  set: (value) => setLocale(value),
});
</script>

<template>
  <MsSettingDialog
    v-model="locale"
    :locales="locales as LocaleObject[]"
  >
    <template #activator="{ props: slotProps }">
      <slot :props="slotProps" />
    </template>
  </MsSettingDialog>
</template>
